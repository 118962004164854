import React from 'react';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import Frontpage from '@fuww/library/src/Frontpage';
import ArticleLayout from '../News/ArticleLayout';
import Head from '../Head';
import HomeLink from '../HomeLink';
import LinkAndAnchor from '../LinkAndAnchor';
import InView from '../InView';
import messages from '../../lib/messages.mjs';
import loading from '../../lib/loading';
import { getFashionWeekCategories } from '../../lib/categoryTags';

const DynamicCategoryTagsSection = dynamic(() => import(
  '../News/ArticleLayout/CategoryTags'
), {
  ssr: false,
  loading,
});

const FashionWeekWeb = () => {
  const intl = useIntl();
  const { categories, getKeywords } = getFashionWeekCategories('en-US');

  const FASHION_WEEK_QUERY_VARIABLES = {
    tagSlugs: getKeywords(),
  };

  const breadcrumbs = [
    <HomeLink />,
    <LinkAndAnchor
      route="fashion-week-web"
      passHref
    >
      {intl.formatMessage(messages['fashion-week-web'])}
    </LinkAndAnchor>,
  ];

  return (
    <>
      <Head
        title={intl.formatMessage(messages['fashion-week-web.title'])}
        description={intl.formatMessage(
          messages['fashion-week-web.metaDescription'],
        )}
      />
      <Frontpage
        breadcrumbs={breadcrumbs}
        intro={intl.formatMessage(messages['fashion-week-web.intro'])}
        description={intl.formatMessage(
          messages['fashion-week-web.description'],
        )}
        mainSection={(
          <ArticleLayout
            featuredVariables={FASHION_WEEK_QUERY_VARIABLES}
            moreNewsVariables={FASHION_WEEK_QUERY_VARIABLES}
            categorySection={(
              <InView>
                <DynamicCategoryTagsSection
                  page="fashion-week-web"
                  categories={categories}
                />
              </InView>
            )}
          />
        )}
      />
    </>
  );
};

export default FashionWeekWeb;
